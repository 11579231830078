import * as React from "react";
import MyModal from "../dialogmodel";

export default function Joblisting() {
  const Jobdesc = "Lorem Ipsum";
  const Jobheading = "Job Title";

  return (
    <>
      <div className="container px-2 mx-auto">
        <div className="grid gap-4 sm:grid-cols-2 p-10 mt-4">
          <MyModal
            job={"Nursery Teacher"}
            desc={Jobdesc}
            heading={Jobheading}
          />
          <MyModal job={"Administrator"} desc={Jobdesc} heading={Jobheading} />
          <MyModal
            job={"Assistant Teacher"}
            desc={Jobdesc}
            heading={Jobheading}
          />
          <MyModal
            job={"Teacher Assistants"}
            desc={Jobdesc}
            heading={Jobheading}
          />
        </div>
      </div>
    </>
  );
}
