import * as React from "react";
import { Form, DatePicker, Input, Space, Spin, message, Radio } from "antd";

// import Input from "../forminput";

export default function Booktour() {
  const [btnDisable, setbtnDisable] = React.useState(false);
  const [form] = Form.useForm();
  const [childGender, setchildGender] = React.useState("");
  const [meettime, setmeettime] = React.useState("");

  function disabledDate(current) {
    const date = new Date();
    const getDay = new Date(current._d).getDay();
    // Can not select days before today and today

    return date >= current || getDay === 0 || getDay === 6;
  }

  function childDate(current) {
    const date = new Date();
    return current >= date;
  }

  function hashLinkScroll() {
    const element = document.getElementById("career");
    if (element) element.scrollIntoView({ behavior: "smooth" });
  }

  const sendEmail = async (formdata) => {
    const mailres = await fetch(`/api/handle`, {
      method: "post",
      mode: "no-cors",
      body: JSON.stringify(formdata),
      headers: { "content-type": "application/json; charset=UTF-8" },
    });

    return await mailres.json();
  };

  async function valid() {
    form.validateFields().catch((error) => {
      const {
        errorFields: [{ name, errors }],
      } = error;

      if (name.length) {
        form.scrollToField(name[0], { behavior: "smooth" });
      }
    });
  }

  const handleSubmit = async (value) => {
    setbtnDisable(true);
    const formDate = value;
    formDate["child-DOB"] = new Date(formDate["child-DOB"]._d).toDateString();
    formDate["meet-date"] = new Date(formDate["meet-date"]._d).toDateString();

    const mailGunResponse = await sendEmail(formDate);
    // const mailGunResponse = { ok: true };
    // console.log(value);
    if (mailGunResponse.ok) {
      setbtnDisable(false);
      form.resetFields();
      message.success("Email sent succesfully");
    } else {
      setbtnDisable(false);
      message.error("Something went wrong, Please try again");
    }
  };

  return (
    <div className="py-20 px-4 bg-accordian_fuzz bg-repeat">
      <div className="mx-auto max-w-4xl flex flex-col  justify-center md:flex-row">
        <div>
          <h4 className="md:mt-32 max-w-xs  text-custom_blue-dark">
            A lifetime of confidence starts here
          </h4>
          <button
            onClick={hashLinkScroll}
            className="px-4 py-8 bg-redbutton bg-no-repeat bg-center bg-contain font-serif mt-4 hover:text-custom_pink-medium"
          >
            Career with us
          </button>
        </div>

        <div className="w-full md:w-3/4 ">
          <h2 className="mr-8 mb-2 text-custom_brown-medium">Book a tour</h2>
          <p className="mb-8 max-w-2xl">
            We would love to take you around our center, show you our facilities
            and answer any questions you may have.
          </p>

          <Form onFinish={handleSubmit} form={form} className="w-full max-w-2x">
            <Form.Item
              name={"child-name"}
              rules={[
                {
                  required: true,
                  message: "Please input your child's name!",
                },
              ]}
            >
              <Input
                placeholder="Child Name"
                className="appearance-none  w-full  rounded py-3 px-4  leading-tight  "
              />
            </Form.Item>

            <Form.Item
              label=""
              name="gender"
              rules={[
                {
                  required: true,
                  message: "Please Select one of the Option from above",
                },
              ]}
            >
              <Radio.Group value={childGender}>
                <Radio.Button value="Boy">Boy</Radio.Button>
                <Radio.Button value="Girl">Girl</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name={"child-DOB"}
              rules={[
                {
                  required: true,
                  message: "Please select your child's Date of Birth!",
                },
              ]}
            >
              <DatePicker
                className="appearance-none  w-full  rounded py-3 px-4  leading-tight  "
                placeholder={"Child Date of Birth"}
                disabledDate={childDate}
              />
            </Form.Item>
            <Form.Item
              name={"parents-name"}
              rules={[
                {
                  required: true,
                  message: "Please input your name",
                },
              ]}
            >
              <Input
                className="appearance-none  w-full  rounded py-3 px-4  leading-tight  "
                name={"parents-name"}
                type="text"
                placeholder={"Parents Name"}
              />
            </Form.Item>
            <Form.Item
              name={"from"}
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please input valid Email address",
                },
              ]}
            >
              <Input
                className="appearance-none  w-full  rounded py-3 px-4  leading-tight  "
                name={"from"}
                type="email"
                placeholder={"Email"}
              />
            </Form.Item>
            <Form.Item
              name={"phone-number"}
              rules={[
                {
                  required: true,
                  message: "Please input your phone number",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
                    if (!value || regex.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "Phone number is invalid, Please Provide a contact number in +9715xxxxxxxx format"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input
                className="appearance-none  w-full  rounded py-3 px-4  leading-tight  "
                name={"phone-number"}
                placeholder={"Phone Number"}
              />
            </Form.Item>
            <Form.Item
              name={"meet-date"}
              rules={[
                {
                  required: true,
                  message: "Please select prefferred Date for the tour ",
                },
              ]}
            >
              <DatePicker
                className="appearance-none  w-full  rounded py-3 px-4  leading-tight  "
                placeholder={"Preferrred Date of Tour"}
                disabledDate={disabledDate}
              />
            </Form.Item>

            <Form.Item
              label=""
              name="meettime"
              rules={[
                {
                  required: true,
                  message: "Please Select one of the Option from above",
                },
              ]}
            >
              <Radio.Group value={meettime}>
                <Radio.Button value="morning">Morning</Radio.Button>
                <Radio.Button value="afternoon">After Noon</Radio.Button>
                <Radio.Button value="evening">Evening</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item name={"hear-about"}>
              <Input
                className="appearance-none  w-full  rounded py-3 px-4  leading-tight  "
                name={"hear-about"}
                placeholder={"How did you hear about us?"}
              />
            </Form.Item>
            <Form.Item name={"questions"}>
              <Input.TextArea
                style={{ height: 120 }}
                className="appearance-none  w-full  rounded py-3 px-4  leading-tight  "
                name={"hear-about"}
                placeholder={"Any question you have for us?"}
              />
            </Form.Item>
            <button
              disabled={btnDisable}
              type="submit"
              onClick={valid}
              className="px-4 py-4 bg-redbutton bg-no-repeat bg-center bg-cover font-serif mt-4 hover:text-custom_pink-medium"
            >
              Send Email
            </button>

            {btnDisable && (
              <Space className="ml-4" size="middle">
                <Spin size="large" />
              </Space>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
}
