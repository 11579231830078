import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function Career() {
  return (
    <div className="pb-16" id="career">
      <div className="xl:container px-4 sm:px-8 lg:px-16 xl:px-20 xl:mx-auto">
        <h2 className="text-custom_blue-dark  max-w-md">
          Career Opportunities
        </h2>
        <div className=" grid grid-cols-1 md:grid-cols-12 gap-8 items-center">
          <div className=" max-w-xl col-span-6 order-1">
            <p className="mb-8"> Want to Join our center as a professional? </p>
            <h2 className="text-custom_brown-medium mb-8 lg:mb-12">
              To Teach is to Touch a Life Forever
            </h2>
            <p className="mt-4">
              please contact us directly, email your CV and other required
              document at{" "}
              <span className="font-bold text-custom_brown-medium">
                {" "}
                admin@lkeecc.com{" "}
              </span>
            </p>
          </div>

          <div className="col-span-6 p-8 ">
            <StaticImage
              src={"../../images/teacher_checking_book.svg"}
              className="w-full mt-0 mr-0 mb-0 ml-0 pt-0 pr-0 pb-0 pl-0 object-contain object-top "
              alt="two little girls"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
