import * as React from "react";
import { graphql } from "gatsby";

import Booktour from "../components/book_tour/book_form";
import Career from "../components/book_tour/career_section";
import Joblisting from "../components/book_tour/job_list";
import Footer from "../components/footer";
import GoTop from "../components/goTop";
import NavBar from "../components/navigation";
import Seo from "../components/seo";
import WhatsappButton from "../components/whatsapp";

const Bookpage = ({ data }) => {
  const description = `LKECC’s chosen philosophy and developmental theory is the Early Years Foundation Stage. We work with children on a one-to-one basis, giving them the opportunity to bring out their best selves in a safe, nurturing and challenging environment`;

  const { navigation } = data.gcms.links[0];

  return (
    <>
      <Seo description={description} title={`Join LKECC`} />

      <NavBar navigation={navigation} textcolor={"text-gray-900"} />
      <Booktour />
      <Career />
      <Joblisting />
      <GoTop />
      <WhatsappButton />
      <Footer navigation={navigation} />
    </>
  );
};

export const query = graphql`
  query bookQuery {
    gcms {
      links(locales: en) {
        navigation {
          id
          slug
          name
        }
      }
    }
  }
`;

export default Bookpage;
